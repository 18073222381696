.HeadingBar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;

  margin-top: 0;
  margin-bottom: 1rem;

  & > * {
    margin-right: 1.5rem !important;
  }

  & > :last-child {
    margin-right: 0 !important;
  }

  h1,
  h2,
  h3 {
    margin-right: auto !important;
  }
}
