.ScheduleSelectorRule {
  border: 1px solid #999;
  border-radius: 8px;
  padding: 16px;

  &-inputs {
    .MuiTextField-root {
      width: 120px;
    }

    @media (max-width: 400px) {
      display: flex;
      flex-direction: column;
      .MuiTextField-root {
        width: 100%;
      }
    }
  }
}
