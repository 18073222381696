.MarkingOverview {
  max-width: 450px;

  &-image {
    width: 100%;
    // When the ImageStreamer is outside the regular page flow, ensure we keep
    // enough space for it.
    padding-bottom: 75%;
    height: 100px;
  }

  .ImageStreamer-img {
    width: 100%;
  }
}
