.SensorHistoryGraph {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &-label {
    flex: 0 0 100px;
  }

  &-graph {
    flex: 1 0 100px;
  }
}
