.WifiStrength {
  &-signal {
    font-weight: 500;
  }

  &-icon {
    display: inline-block;
    height: 30px;
    width: 30px;
    margin-right: 5px;

    &--good {
      #fourth {
        fill: lightgray;
      }
    }

    &--mediocre {
      #third,
      #fourth {
        fill: lightgray;
      }
    }

    &--low {
      #second,
      #third,
      #fourth {
        fill: lightgray;
      }
    }

    &--unavailable {
      fill: lightgray;
      display: block;
    }
  }
}
