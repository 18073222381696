.ErrorBoundary {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: #eee;
  .title {
    font-weight: 600;
  }
  .btn-action {
    min-width: 100px;
  }

  code {
    color: #444;
    font-size: 20px;
    font-weight: normal;
    margin-left: 8px;
  }
}
