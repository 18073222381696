.Button {
  line-height: 26px;
  min-width: 40px !important;

  &--small {
    line-height: 26px;
    padding: 6px 14px !important;
    .Button-icon.Icon {
      width: 20px;
      height: 20px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  &--no-label {
    padding-left: 20px !important;
    padding-right: 20px !important;
    .Button-icon {
      padding-right: 0;
      margin-right: 0;
    }
  }

  &--small.Button--no-label {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  &-icon.Icon {
    margin-right: 5px;
    width: 25px;
    height: 25px;
    margin-top: 1px;
    margin-bottom: 1px;
    display: flex;

    &-square {
      margin-right: 14px;
      border-radius: 6px;
      > svg {
        fill: white;
      }
    }

    &--primary {
      background: #84b93c;
    }

    &--primary-light {
      background: #d1eda9;
      > svg {
        fill: #516d1c;
      }
    }

    &--secondary {
      background: #fd2d0b;
    }

    &--secondary-light {
      background: #fbdfdf;
      > svg {
        fill: #581a1a;
      }
    }

    &--default {
      background: #737373;
    }
  }

  &--big-icon {
    &.MuiButton-root.MuiButton-default {
      padding-top: 2px;
      padding-bottom: 2px;
    }
    .Button-icon.Icon {
      margin-top: 0;
      margin-bottom: 0;
      width: 40px;
      height: 40px;
    }
  }

  &--icon-square {
    .Button-icon.Icon {
      width: 30px;
      height: 30px;
    }
    &.MuiButton-root.MuiButton-default.Mui-disabled {
      background-color: transparent !important;
      color: #888 !important;
    }
  }
}
