@import 'src/styles/lib';

.NewImpersonal {
  &-roles {
    margin-top: -35px;

    @include mobile {
      margin-top: 0;
    }
  }
}
