@import '../styles/lib';

.BorderWrapper {
  border: 1px solid #707070;
  border-radius: 10px;
  padding: 30px;
  @include mobile {
    padding: 15px;
  }
}
