.App {
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow: hidden;

  &-public {
    overflow: auto;
  }

  &-header {
    flex: 0 0 auto;
  }

  &-body {
    width: 100%;
    height: 100%;
    min-height: 100%;
    background-color: #e8e8e8;
    overflow: auto;
  }
}
