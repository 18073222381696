.FilterInput {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &-input {
    width: 100%;
    font-weight: 500;
  }

  &-icon {
    min-width: 20px;
    color: #9b9b9b;
    text-align: center;
    &-clear {
      cursor: pointer;
      &:hover {
        color: #7d7d7d;
      }
    }
  }
}
