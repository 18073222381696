@import '../../../styles/lib';

.ManageHelpPage {
  &__sub-heading {
    font-size: 24px !important;
    font-weight: 500 !important;
    padding-top: 10px;

    margin-left: 16px !important;

    @include mobile {
      margin-left: 0.5rem !important;
    }
  }
}

.SplashScreen {
  &-title {
    font-size: 24px !important;
    font-weight: 500 !important;
    margin: 40px 0 40px 0 !important;
  }
  &-download {
    margin-top: 30px !important;
    margin-left: 15px !important;

    svg {
      background: transparent !important;
    }

    path {
      fill: #444;
    }
  }
}
