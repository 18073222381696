.ModalWrapper {
  @media (min-width: 678px) {
    //max-width: 600px !important;
  }

  &-content {
    //padding: 30px 20px 35px 38px;
    padding: 30px;
    border-radius: 15px !important;
  }

  &-header {
    display: flex;
    padding-bottom: 20px;
  }

  .modal-body {
    padding: 20px 0 0 0;
  }

  &-title-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    padding-right: 10px;
    .exclamation {
      margin-right: 10px;
      svg {
        font-size: 35px;
        color: #fd2d0b;
      }
    }
    .title {
      flex: 1;
    }
  }

  &-title {
    font-weight: 700;
    font-size: 22px;
    color: #3f3f3f;
    margin: 0;
  }

  &-close {
    &-btn {
      display: flex;
      align-items: center;
      font-size: 30px;
      padding: 0;
      margin: 0;
      border: none;
      background: none;
      color: black;
      opacity: 0.8;
      &:focus {
        outline: none;
      }
      &:hover {
        opacity: 1;
      }

      span {
        font-size: 15px;
        font-weight: bold;
        margin-right: 5px;
      }
    }
  }

  .modal-footer {
    justify-content: flex-start;
    padding: 80px 0 0 0;
    border: none;
  }

  &-confirm-btn {
    margin-right: 15px !important;
  }

  &-with-tree-view {
    .TreeView {
      overflow: auto;
      max-height: 350px;
      margin-left: -23px;
    }
  }
}
