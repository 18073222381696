.ViewImageModal {
  max-width: 100% !important;
  width: 100%;
  height: 100%;
  margin: 0 !important;
  padding: 0; // 20px // To add a transparent margin around the image

  .modal-content {
    height: 100%;
    border: 0 !important; // Hide 1px border to make sure image is full viewport width
  }

  &-body {
    width: 100%;
    height: 100%;
  }

  &-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &-close {
    position: absolute;
    top: -2px;
    right: 10px;
    padding: 5px;
    font-size: 31px;
    cursor: pointer;
    color: #000;
    &:hover {
      color: #f1f1f1;
    }
  }
}
