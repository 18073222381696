@import './src/styles/lib';

.IpWhiteListForm {
  margin: 20px 0;

  &-wrapper {
    display: flex;
    align-items: flex-start;

    @media (max-width: 1200px) {
      flex-direction: column;
    }

    &-row {
      flex-wrap: nowrap !important;

      @media (max-width: 1200px) {
        flex-direction: column !important;
      }

      .MuiFormControl-root {
        margin-right: 10px;
      }
    }
  }

  .MuiFormHelperText-root.Mui-error {
    margin-bottom: -23px;
    padding-bottom: 23px;
  }
}
