.Storybook {
  height: 100%;
  overflow: auto;
  > div {
    padding: 15px;
  }
}

.alert {
  margin: 10px 0;
  border-radius: 8px;
}

.alert-dot {
  position: relative;
  &:after {
    content: '';
    width: 6px;
    height: 6px;
    position: absolute;
    top: -1;
    right: -8px;
    border-radius: 50%;
    background: red;
  }
}

.btn-xs,
.btn-group-xs > .btn {
  padding: 0.15rem 0.4rem;
  font-size: 0.875rem;
  line-height: 1.3;
  border-radius: 0.2rem;
}

// hr {
//   border: 0;
//   height: 1px;
//   background-color: #999;
//   width: 100%;
//   margin-top: space(4);
//   margin-bottom: space(4);
// }

/**
 * Development mode
 */
/*
*:not(.no-override-bg) {
  color: black !important;
  background-color: white !important;
  font-family: 'Comic Sans MS', sans-serif !important;
}
*/

.fade-in-up {
  animation-name: fadeInUpAnimation;
  animation-duration: 0.3s;
}

@keyframes fadeInUpAnimation {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
.EventForm {
  padding: 15px;
  pre {
    background: rgb(243, 243, 243);
    padding: 5px;
  }
}

.MuiTable-root {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

/**
*   Removes wwhite notch from input field
*/
.MuiOutlinedInput-notchedOutline {
  margin-top: 5px !important;
  legend {
    display: none;
  }
}

.text-field-input {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.Toastify__toast {
  padding: 10px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #fff;
  border-radius: 15px;
  box-shadow: 1px 7px 14px 1px rgba(29, 26, 26, 0.2);

  &-body {
    margin: 0 0 0 10px;
    white-space: pre-line;
  }

  &-close-btn {
    font-size: 23px;
    &:hover {
      opacity: 0.8;
    }
  }
}

.Toastify__toast--success {
  background: #8fc137;
}

//Override KeyboardDatepicker footer buttons
.MuiDialogActions-root {
  flex-direction: row-reverse;

  > button {
    color: #fff;
  }

  &.MuiDialogActions-spacing > :not(:first-child) {
    margin-left: 0;
    margin-right: 8px;
  }
  &.MuiDialogActions-spacing > :first-child {
    background-color: #888888;
    &:hover {
      background-color: #d5d5d5;
    }
  }
}

//Override input autofill styling
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
}
input:-webkit-autofill::first-line {
  color: #3f3f3f;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
}

hr {
  margin: 22px 0;
  border-color: black;
}

// Add a general color override for buttons to avoid
// the Safari bug in which buttons are rendered in blue
button {
  color: #212529;
}
