.AccessDenied {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  &-content {
    max-width: 500px;
    margin: 100px auto;
    text-align: center;
  }
}
