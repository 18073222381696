.OneTimeCodeSubmitForm {
  background-color: #f1f1f1;
  padding: 10px;
  margin: 20px 0;
  border-radius: 10px;
  max-width: 380px;
  background: #8eb750;

  p.Mui-error {
    margin: 8px 14px 0;
  }

  &-new-code {
    color: #fff !important;
    width: max-content;
  }

  .MuiInputBase-root {
    background-color: white;
    border-radius: 10px;
  }

  // &-input {
  //   fieldset {
  //     z-index: -1;
  //     background-color: #fff;
  //     border-radius: 10px;
  //   }
  // }

  &-icon,
  .IconButton.MuiButton-root {
    padding: 0 !important;

    svg {
      fill: #8eb750 !important;
      background-color: #fff !important;
      margin: 0 !important;
    }
  }

  &-device {
    color: #fff;
    font-weight: 500;
    margin: 0;
  }
}
