.SortIcon {
  display: inline-flex;
  flex-direction: column;
  cursor: pointer;
  padding: 0 10px;

  &--asc {
    & > svg:last-child {
      fill: black;
    }
  }

  &--desc {
    & > svg:first-child {
      fill: black;
    }
  }

  & > svg:first-child {
    margin-bottom: 5px;
  }
}

.ArrowSVG {
  fill: #00000042;
  &--disabled {
    fill: gray;
  }
  &--up {
    transform: rotate(180deg);
  }
}
