.Badge {
  font-size: 12px;
  font-weight: 700;
  background-color: #6c757d;
  color: white;
  border-radius: 3px;
  display: inline-block;
  padding: 2px 4px;
}

.Badge--error {
  background-color: #c9533d;
  color: white;
}

.Badge--success {
  background-color: #84b93c;
  color: white;
}

.Badge--info {
  background-color: #6c757d;
  color: white;
}
