.DashboardHousingUnit {
  background-color: #709739;
  color: white;
  fill: white;
  padding: 10px 19px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 30px;

  width: calc(20% - 20px);
  margin: 4px 10px;

  font-size: 20px;

  & > :first-child {
    flex: 1;
    align-self: center;
  }

  &-icon {
    margin-top: -4px;
    margin-right: -7px;
    height: 40px;
    width: 40px;
  }

  &--alarm {
    background-color: #ec3926;
    color: white;
    fill: white;
  }

  &--alert {
    background-color: #fcea50;
    color: black;
    fill: black;
  }

  &--disconnected {
    background-color: #848484;
  }
}
