.SensorHistory {
  &-header {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    & > div:first-child {
      margin-right: auto;
    }
  }

  &-graphs {
    @media (min-width: 1000px) {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
    }

    margin-left: -20px;
    margin-right: -20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &-graph-column {
    flex: 1 1 0;
    margin-left: 20px;
    margin-right: 20px;
  }
}
