@import './src/styles/lib';

.Settings {
  &-list-item {
    position: relative;
    display: inline-flex;
    width: 100%;
    align-items: center;
    flex: 1 1;
  }
  &-indicator {
    right: 1px;
  }
  &-content {
    display: flex;
    position: relative;
    height: 100%;
  }

  &-sidebar {
    width: 100%;
    height: 100%;
    max-width: 235px;

    margin-right: 50px;
    border-right: 1px solid #8f8f8f;

    &--desktop {
      @media (max-width: 991px) {
        display: none;
      }
    }

    &--mobile {
      max-width: 100%;
      .MuiListItem-gutters {
        padding-left: 0 !important;
      }
    }

    &-list {
      padding-top: 0 !important;
      padding-right: 10px !important;
      &-item > .MuiTypography-root {
        font-weight: 700;
        font-size: 16px;
      }
    }
  }

  &-page {
    display: flex;
    flex: 1 0 0;
    padding-top: 8px;
    padding-left: 20px;
    overflow-x: hidden;
  }

  .radio-with-image {
    &-option {
      display: flex;
      margin-bottom: 10px;

      img {
        margin-right: 10px;
        width: 100px;
        height: 75px;
      }

      @include desktop {
        img {
          flex: 0 0 100px;
        }
      }

      @include mobile {
        flex-direction: column;
        margin-bottom: 20px;

        img {
          flex: 0 0 75px;
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.Manage .Settings {
  h2 {
    margin: 0 0 32px 0;
  }
}
