@import 'src/styles/lib';

.CenteredCard {
  position: relative;
  z-index: 1;
  margin-top: 100px;

  @include tablet {
    margin-top: 20px;
  }

  h1 {
    font-weight: 700;
    font-size: 33px;
    color: #555555;
    margin-bottom: 0;

    @include mobile {
      font-size: 20px;
    }
  }

  &-wrapper {
    position: relative;
    border-radius: 26px;
    padding: 34px 40px 50px 43px;
    background: #fff;
    border: 11px solid #dddddd;

    &-icon {
      background: url('../../images/roommate_logo_grey.png');
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      width: 50px;
      height: 50px;
      right: 0;
      top: 13px;

      @media (min-width: 992px) {
        display: block;
        width: 63px;
        height: 90px;
        right: 40px;
        top: 18px;
      }
    }

    @include tablet {
      padding: 20px;
    }
  }

  .form-control-login {
    width: 100%;
    border-radius: 8px;
    /*border: 1px solid #555555;*/
    color: #555555;
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    line-height: 24px;
    &:focus {
      border-color: #87c743;
      box-shadow: 0 0 0 0.2rem rgba(130, 182, 74, 0.25);
    }
  }

  .btn-default {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 22px;
    line-height: 25px;
    padding: 11px;
    border: none;
    border-radius: 8px;
    color: #fff;
  }

  .btn-green {
    background: #82b64a;
    &:hover {
      color: #fff;
      background: darken(#82b64a, 8%);
    }
  }

  .btn-grey {
    background: #777777;
    &:hover {
      color: #fff;
      background: darken(#777777, 8%);
    }
  }

  .btn-login {
    width: 100%;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .actions-wrapper {
    display: flex;
    flex: 1;
    @media (max-width: 992px) {
      flex-direction: column;
      button {
        margin: 10px 0;
      }
    }
  }
}
