.VideoModal {
  max-width: 1200px !important;

  &-wrapper {
    max-height: 650px;
    height: 98vh;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  &-logo {
    text-align: center;
    margin-top: 15px;
    img {
      height: 50px;
    }
  }
}
