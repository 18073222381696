.CareCard.SensorCard {
  &--offline {
    background-color: #fbdfdf;
    border-color: #fd2d0b;
  }

  &--demo {
    background-color: #d1eda9;
    border-color: #84b93c;
  }
}

.SensorCard {
  &-event--alarm {
    color: white;
  }

  &-event-icon {
    .CareCard-icon {
      top: 0;
      right: 0;
      height: 100%;
      transform: unset;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      border-radius: 0 5px 5px 0;
    }

    &--alarm {
      .CareCard-icon {
        background-color: #ec3926;
      }
    }
    &--alert {
      .CareCard-icon {
        background-color: #fcea50;
      }
    }
  }
}
