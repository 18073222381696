@import '../../styles/lib';

.Pagination {
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  @include mobile {
    flex-wrap: wrap;
    & > div {
      margin-bottom: 20px;
    }
  }
}
