.IconButton {
  // min-width: 40px !important;

  // &--small {
  //   line-height: 26px;
  //   padding: 6px 14px !important;
  //   .Button-icon {
  //     width: 16px;
  //     height: 16px;
  //     margin-top: 5px;
  //     margin-bottom: 5px;
  //   }
  // }

  // &--no-label {
  //   padding-left: 20px !important;
  //   padding-right: 20px !important;
  //   .Button-icon {
  //     padding-right: 0;
  //     margin-right: 0;
  //   }
  // }

  // &--small.Button--no-label {
  //   padding-left: 15px !important;
  //   padding-right: 15px !important;
  // }

  // &-icon {
  //   &-square {
  //   }

  // }

  color: #3f3f3f;
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;

  &.MuiButton-root {
    padding: 3px 6px 3px 0 !important;
    min-width: 0;
    &:hover {
      background-color: #eee;
    }
  }

  .IconButton-icon {
    margin: 3px 12px 3px 0;
    min-width: 30px;
    width: 30px;
    height: 30px;

    // margin-right: 14px;
    border-radius: 6px;
    fill: white;
    background-color: #737373;
    border-radius: 6px;
    // padding: 4px;
  }

  &--no-label {
    &.MuiButton-root.MuiButton-icon-square {
      padding: 3px;
    }
    .IconButton-icon {
      margin-right: 3px;
    }
  }

  &.disabled {
    color: #777;
  }

  &--big-icon {
    .IconButton-icon {
      width: 40px;
      height: 40px;
    }
  }

  &--primary {
    .IconButton-icon {
      background-color: #84b93c;
    }
  }

  &--secondary {
    .IconButton-icon {
      background-color: #c9533d;
    }
  }

  &--transparent {
    .IconButton-icon {
      background-color: transparent;
      fill: #737373;
    }
  }

  &.MuiButton-root.MuiButton-default.Mui-disabled {
    background-color: transparent !important;
    color: #888 !important;
  }
}
