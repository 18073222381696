.ScheduleSummaryCard {
  border-collapse: collapse;

  td {
    border-top: 1px solid #999;
    border-bottom: 1px solid #999;
    padding-right: 16px;

    font-size: 20px;
    padding: 8px 24px 8px 2px;
  }

  th {
    font-size: 14px;
    font-weight: 500;
  }
}
