.HoverPointDetails {
  font-weight: normal;
  text-align: center;

  font-size: 16px;

  span {
    display: inline-block;
    padding: 0 6px;
    text-align: center;
  }
}
