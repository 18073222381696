@import '../../styles/lib';

.AudioPlayer {
  svg {
    fill: $primary;
  }

  &--disabled {
    cursor: not-allowed;
    svg {
      fill: #c4c4c4;
    }
  }
  .Icon {
    width: 25px;
    height: 25px;
  }
}
