@import '../../../styles/_lib.scss';

$label-width: 125px;
$min-chart-width: 75%;
$center-line-thickness: 1.5px;
$center-line-color: #a5aa99;
$bar-color: #84b93c;

.BehavioralDataChart {
  position: relative;
  pointer-events: all;

  &__axis {
    font-family: Roboto Condensed;
    color: #212529;
    font-size: 14px;
    font-weight: 600;
    color: #424549;

    &--x {
      font-size: 17px;

      .tick {
        stroke-width: 0px;
        color: #212529;
      }
    }

    &--y {
      fill: currentColor;

      .tick {
        text {
          transform: translateX(5px);
        }
      }
    }
  }

  &__boolean-series-icon {
    margin-right: 5px;
    width: 42px;
    height: 42px;
    path {
      fill: #424549 !important;
    }

    @include mobile {
      width: 30px;
    }
  }

  &__boolean-series {
    font-size: 0.875rem;
    padding: 0 0 15px 0;
    display: flex;
  }

  &__boolean-icons {
    display: flex;
    flex-direction: column;
  }

  &__boolean-items {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__boolean-series-item {
    width: 100%;
    min-height: 42px;
    display: flex;
    align-items: center;
  }

  &__boolean-series &__boolean-series-item svg {
    display: block;
  }

  &__boolean-series &__boolean-series-item line {
    stroke: $center-line-color;
    stroke-width: $center-line-thickness;
  }

  &__boolean-series &__boolean-series-item path {
    fill: none;
    stroke: $bar-color;
  }
}

.BehavioralDataChartOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
