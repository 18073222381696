@import '../../../../styles/lib.scss';

.BlockedUserModal {
  @media (max-width: 991px) {
    &.modal-dialog {
      max-width: 100%;
      margin: 1.75rem 15px;
    }
  }

  .ModalWrapper-header {
    padding-bottom: 0;
  }

  .ModalWrapper-close {
    z-index: 101;
  }

  .modal-body {
    padding-top: 0;
    margin-top: -30px;

    z-index: 100;
  }

  .modal-content {
    padding: 45px 40px 40px 40px;
    @media (max-width: 767px) {
      padding: 30px;
    }
  }

  .modal-footer {
    padding-top: 40px;
    @media (max-width: 767px) {
      padding-top: 30px;
    }
  }

  &-container {
    display: flex;
    //max-height: 368px;
    //overflow: auto;

    @include mobile {
      max-height: inherit;
      flex-direction: column-reverse;
    }
  }

  &-title {
    font-weight: 700;
    font-size: 22px;
    color: #3f3f3f;

    margin-bottom: 30px;

    @media (min-width: 577px) {
      margin-bottom: 45px;
    }
  }

  &-selected-users {
    flex: 1;

    &-list {
      flex: 1;
      height: 100%;
      min-height: 364px;
      max-height: 364px;
      overflow: auto;

      border: 1px solid #656565;
      border-radius: 5px;
      padding: 15px;

      &-item {
        display: flex;
        align-items: center;
        &-close {
          cursor: pointer;
          font-size: 28px;
          margin-right: 10px;
          opacity: 0.8;
          &:hover {
            opacity: 1;
          }
          @include mobile {
            font-size: 20px;
            margin-right: 5px;
          }
        }

        &-label {
          font-weight: 500;
          font-size: 20px;
          color: #535353;
          @include mobile {
            font-size: 16px;
          }
        }
      }
    }
  }

  &-search-user {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 20px;

    @include mobile {
      margin-left: 0;
      margin-bottom: 20px;
    }

    .MuiInputBase-root {
      margin-top: 0;
    }

    &-input {
      margin-bottom: 20px;
    }

    &-results {
      border: 1px solid #656565;
      border-radius: 4px;
      height: 305px;
      overflow: auto;
      &-item {
        padding: 5px 10px;
        cursor: pointer;
        border-bottom: 1px solid #d0d0d0;
        font-size: 16px;
        font-weight: 500;
        color: #535353;
        &:hover {
          background-color: #eee;
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  &-no-results {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
}
