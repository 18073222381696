@import '../../../styles/_lib';

.ManageSidebar {
  width: 300px;
  height: calc(100% - 96px);
  padding: 15px;
  position: fixed;
  left: 15px;
  top: 81px;

  @media screen and (min-width: 1200px) {
    // Must correspond to the 300px in Manage.scss
    width: 400px;
  }

  @include tablet {
    max-width: 100%;
    width: calc(100% - 30px);
    transform: translateX(-110%);
    transition: 0.3s;
    z-index: 100;
    &--opened {
      transform: translateX(0);
    }
  }

  &-mobile-close {
    display: none;
    @include tablet {
      display: flex;
      justify-content: flex-end;
    }
  }

  &-head {
  }

  &-green-indicator {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    // right: 1px;
    width: 9px;
    height: 9px;
    background-color: #c4c4c4;
    border-radius: 100%;
  }
  &-body {
    height: calc(100% - 128px);
    margin-top: 30px;
    padding-right: 10px;
    overflow: auto;

    // &:after {
    //   position: absolute;
    //   content: '';
    //   width: calc(100% - 35px);
    //   height: 43px;
    //   bottom: 13px;
    //   left: 0;
    //   box-shadow: inset 13px -22px 25px -2px #ffffff;

    //   @include tablet {
    //     width: 100%;
    //   }
    // }

    @include tablet {
      height: calc(100% - 158px);
    }
  }

  &-tree-view {
    padding-bottom: 53px;
  }
}
