@import '../../../../styles/lib';

.HeaderSearch {
  position: relative;
  width: 360px;
  &-input {
    width: 100%;
    padding: 2px 10px;
    border: none;
    border-radius: 2px;
    outline: none !important;

    &:focus {
      outline: none;
    }
  }

  &-list {
    position: absolute !important;
    max-height: 500px;
    min-width: 360px;
    overflow: auto;
    top: 39px;
    left: 0;
    background: #fff;
    @include mobile {
      width: 100%;
    }
    a {
      width: 100%;
    }
  }

  &-list-item {
    cursor: pointer;
    display: flex;
    align-items: flex-start !important;
    flex-direction: column;
    small {
      margin-right: 5px;
    }
  }

  &-list-header {
    text-transform: capitalize;
    font-weight: 600;
    margin: 10px 0 0 0;
    border-top: 2px solid #eee;

    &:first-child {
      margin-top: 0;
      border-top: none;
    }
  }

  &-results {
    width: 500px;
    padding: 0;
    background-color: white;

    @include mobile {
      width: 100%;
    }
  }

  &-results-list {
    & > * {
      // border-radius: 5px;
      // border: 1px solid #eee;
      border-bottom: 1px solid #ccc;
      // margin-bottom: 5px;

      &:last-child {
        border-bottom: 0;
        // margin-bottom: 0;
      }
    }
  }
}
