.MarkingObjectControls {
  margin: 24px 0;

  table {
    width: 100%;
  }
  thead > tr:first-child {
    background-color: #707070;
    color: white;
    & > th {
      border: 1px solid #707070;
    }
  }
  thead > tr:nth-child(2) > th,
  td {
    border: 1px solid #707070;
    text-align: center;
  }

  td {
    line-height: 40px;
  }

  tr.warning {
    background-color: #fff3cd;
  }
  tr.reject {
    background-color: #f5dee6;
  }
}
