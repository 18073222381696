@import '../../styles/lib';

.PublicHeader {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-right: 100px;

  @include mobile {
    padding-right: 20px;
  }

  &__logo-wrapper {
    flex-grow: 1;
  }

  &-logo {
    max-width: 250px;
  }
}
