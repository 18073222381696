@import '../../../styles/lib';

$warning: #f7c144;
$warning-dark: darken($warning, 15);

$alarm: #ec3926;
$alarm-alt: darken($alarm, 15);

.CareSidebar {
  position: relative;
  font-family: Roboto Condensed;
  height: calc(100% - 50px);
  padding: 10px;
  overflow: hidden;

  background: #cbcbcb;

  @include mobile {
    position: absolute;
    flex-basis: 100%;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 0 10px 10px 10px;
  }

  &-with-search {
    .CareSidebar-body {
      max-height: calc(100% - 132px);
      @include mobile {
        max-height: calc(100% - 142px);
      }
    }
  }

  &-head {
    &-filter-input {
      margin-top: 10px;
    }

    @include mobile {
      padding-top: 10px;
    }

    &-filter {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 20px;

      &-input {
        flex: 1;
        margin-right: 3px;
      }

      .MuiFormGroup-root {
        width: 100%;
      }
    }

    &-active-zone {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 10px;
      font-size: 14px;
      &-name {
        padding-right: 10px;
        flex: 1;
      }
    }
  }

  &-body {
    height: 100%;
    max-height: calc(100% - 78px);
    margin-top: 15px;
    padding-right: 10px;
    margin-right: -10px;
    padding-bottom: 20px;
    overflow: auto;

    @include mobile {
      margin-right: -10px;
      margin-left: -10px;
      padding-left: 10px;
      padding-right: 10px;
      max-height: calc(100% - 89px);
    }

    &::after {
      position: absolute;
      bottom: 8px;
      left: 0;
      content: '';
      width: calc(100% - 15px);
      box-shadow: inset 1px -10px 9px 0px #cbcbcb;
      height: 19px;

      @include tablet {
        width: 100%;
      }

      @include mobile {
        width: calc(100% - 10px) !important;
      }
    }

    &-zone-selected {
      max-height: calc(100% - 126px);

      @include mobile {
        max-height: calc(100% - 136px);
      }

      &--with-search {
        max-height: calc(100% - 179px) !important;
        @include mobile {
          max-height: calc(100% - 189px) !important;
        }
      }
    }
  }

  &-btn {
    font-size: 20px;
    color: #3f3f3f;
    cursor: pointer;

    &-select-zone {
      padding-top: 3px;
      &-icon {
        width: 40px;
        height: 40px;
      }
    }

    &:hover {
      color: darken(#3f3f3f, 10%);
    }
    &-remove-zone {
      font-size: 25px;
      margin-right: 10px;
    }
  }

  &-old-data-card {
    margin-top: 15px;
    padding: 10px;
    border-radius: 8px;
    background-color: #fff3cd;
    border: 3px solid #ffecb5;
    color: #664d03;
    display: flex;
    align-items: center;
    line-height: 15px;

    &-icon {
      margin-right: 10px;
    }
  }
}
