.LevelIndicator {
  display: flex;
  align-items: center;

  &::before {
    display: inline-block;
    content: '';
    // background-color: #6c757d;
    border-radius: 12px;
    width: 12px;
    height: 12px;
    margin-right: 5px;
  }
}

.LevelIndicator--alert::before {
  background-color: #fbe045;
}
.LevelIndicator--alarm::before {
  background-color: #ec5749;
}
