.ReadOnlyMarkingCanvasOverlay {
  // This is the actual canvas. We control its width/height using JS when redrawing,
  // so here we just make sure its glued to the top left corner in the container.
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  margin: auto;
}
