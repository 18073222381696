.CareIndicators {
  display: flex;
  justify-content: space-between;

  margin-left: -5px;
  margin-right: -5px;

  & > .CareIndicator {
    flex: 1 1 0;
    margin-left: 5px;
    margin-right: 5px;
  }
}
