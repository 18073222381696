.CareCard.EventCard {
  &--alarm {
    background: #ec3926;
    color: #fff;
    fill: white;
  }

  &--alert {
    background: #fcea50;
  }
}
