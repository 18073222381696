.ImageStreamer {
  width: 100%;
  height: 100%;
  position: relative;

  &-img {
    display: block;
    @media screen and (max-width: 613px) {
      width: 100% !important;
      height: auto !important;
    }
  }

  &-demo-overlay {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #eee;
    font-size: 24px;
    font-weight: bold;

    &-icon {
      width: 50px;
      fill: #eee;
      margin-right: 8px;
    }
  }
}
