@import './src/styles/lib';

.InfoPage {
  font-size: 18px;
  padding: 50px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  @include mobile {
    overflow-y: unset;
    height: unset;
  }

  // &__accordion-title {
  // }

  &__accordion-subtitle {
    color: #777879;
    @include mobile {
      display: block;
    }
    @include desktop {
      margin-left: 12px;
    }
  }

  &__behavior-button {
    display: block;
    border-radius: 8px;
    padding: 3px 10px;
    border: 3px solid #999;
    background-color: #eee;
    font-weight: 500;
    color: black;
    &:hover {
      background-color: darken(#eee, 5);
    }
  }
  &__accordions {
    margin-bottom: 35px;
    .MuiPaper-elevation1 {
      box-shadow: unset !important;
    }
    .MuiAccordion-root:before {
      content: unset;
    }
    .MuiAccordion-root {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      font-weight: 500;

      .MuiAccordionSummary-expandIcon {
        margin-left: 5px;
      }
    }

    .MuiButtonBase-root {
      justify-content: flex-start;
    }
    .MuiAccordionSummary-content {
      @include desktop {
        flex-grow: unset;
      }
      @include mobile {
        flex-direction: column;
      }
    }
    .MuiAccordion-root.Mui-disabled {
      background-color: rgba(0, 0, 0, 0.04);
      cursor: not-allowed;
    }
  }

  &__heading {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    h5 {
      margin: 0;
      font-weight: bold;
    }
    &-icon {
      width: 52px;
      height: 52px;
    }
  }

  &__heading {
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    h1 {
      margin: 0;
      font-weight: bold;
      font-size: 20px;
    }

    &-icon {
      width: 52px;
      height: 52px;
    }
  }

  @include mobile {
    padding: 20px 10px 50px 10px;
  }

  h2 {
    font-size: 22px;
    font-weight: bold;
    margin: 0;
  }

  &__head-wrapper {
    margin: 0 0 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include mobile {
      flex-direction: column;
      & > h2 {
        margin-bottom: 20px;
      }
    }
  }

  &-info {
    display: flex;
    flex-flow: row nowrap;
    .Icon {
      margin-right: 16px;
    }
    margin-bottom: 48px;
  }

  &-events {
    tr {
      border-top: 1px solid #666;
      border-bottom: 1px solid #666;
    }

    td {
      padding: 8px 24px 8px 4px;
    }
  }
}
