.TimeIntervalPicker {
  display: flex;
  padding: 10px 0;
  align-items: center;

  &-inputs {
    display: flex;

    .MuiFormGroup-root {
      margin-right: 10px;
    }
  }

  &-info {
    margin-top: 20px;
    font-size: 14px;
  }
}
