.SuperVendorNavigation {
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
  background-color: white;
  padding: 80px 8px 8px 8px;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.2),
    0 0 1px 0 rgba(0, 0, 0, 0.12);
}
