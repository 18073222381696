@import '../../../styles/lib';

.EventSettingsInfoTable {
  td {
    padding: 3px 0;
    padding-right: 32px;
  }

  tr {
    border-bottom: 1px solid #e0e0e0;
  }

  &-icon {
    width: 28px;
    height: 28px;
    flex: 0 0 28px;
  }

  &-warning {
    display: flex;
    align-items: center;

    .EventSettingsInfoTable-icon {
      fill: #ffcc00;
    }
  }

  &-error {
    display: flex;
    align-items: center;
    color: #ec4838;
  }

  &-row {
    &-eventLabel-mobile {
      display: none;
    }
  }

  @include tablet {
    width: 100%;
    font-weight: 400;

    thead {
      display: none;
    }

    &-row {
      display: grid;
      grid-template-columns: 54px 1fr;
      grid-template-rows: auto auto auto;
      column-gap: 10px;

      &-eventLabel {
        display: none;
      }

      &-eventLabel-mobile {
        display: inline-block;
      }

      &-eventLevelIndicator {
        display: inline-block;
        align-self: end;
        grid-row: 1;
        grid-column: 2;

        .LevelIndicator {
          display: inline-block;
          margin-right: 4px;
          font-weight: 500;
          &::after {
            content: ':';
          }
        }
      }

      &-eventSchedule {
        grid-row: 2;
        grid-column: 2;
      }

      &-eventWarnings {
        grid-row: 3;
        grid-column: 2;
        font-weight: 500;
      }

      &-eventIcon {
        grid-row: 1/4;

        .EventTypeIcon {
          width: 52px;
          height: 52px;
        }
      }
    }
  }
}
