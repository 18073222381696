.Logs {
  &-results {
    width: 100%;
    border: 1px solid #707070;
    border-radius: 6px;
    height: 400px;
  }

  &-actions {
    margin: 50px 0 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    button {
      color: #fff;
    }

    @media screen and (max-width: 1300px) {
      display: block;
      .Pager {
        margin-top: 30px;
      }
    }
  }
}
