.HamburgerButton {
  position: relative;
  cursor: pointer;
  width: 25px;
  height: 19px;
  outline: unset;
  border: unset;
  padding: 0;
  background: transparent;

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    border-radius: 2px;
    background-color: #cdcdcd;
    transform: translateY(0) rotate(0);
    transition: transform 0.2s cubic-bezier(0.3, 0, 0.2, 1),
      opacity 0.1s cubic-bezier(0.3, 0, 0.2, 1);

    &:nth-child(1) {
      top: 0px;
    }
    &:nth-child(2) {
      top: 8px;
    }
    &:nth-child(3) {
      top: 8px;
    }
    &:nth-child(4) {
      top: 16px;
    }
  }

  &--open {
    span {
      &:nth-child(1) {
        opacity: 0;
      }
      &:nth-child(2) {
        transform: rotate(45deg);
      }
      &:nth-child(3) {
        transform: rotate(-45deg);
      }
      &:nth-child(4) {
        opacity: 0;
      }
    }
  }
}
