@import '../../../../src/styles/lib';

.ListPermissions {
  &-actions {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 1200px) {
      flex-direction: column;
      margin-bottom: 10px;
    }

    &-new {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &-filter {
      margin-top: 0 !important;
    }
  }
}
