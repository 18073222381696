.HelpFooter {
  padding-bottom: 24px;

  h3.HelpFooter-heading {
    font-size: 16px;
    font-weight: 600;
    margin: 24px 0 8px 0;
  }

  &-simulation {
    padding: 8px;
    border-radius: 4px;
    color: #721c24;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    font-size: 12px;
    line-height: 16px;
  }
}
