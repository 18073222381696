.MobileAppSounds {
  &-input {
    flex-direction: row !important;
    align-items: flex-end;
    margin-top: 3rem;

    .MuiFormControl-root {
      width: 100%;
      max-width: 200px;
    }
  }
  &-player {
    margin: 7px !important;
  }
  .MuiInputLabel-formControl {
    top: -13px;
    white-space: nowrap;
  }
  .MuiFormLabel-root {
    color: #3f3f3f;
    display: block;
    font-size: 15px;
    font-family: Roboto;
    font-weight: 500;
    transform: translate(0, -0.2rem) scale(1);
  }
}
