.CareMainPageMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  padding: 10px;

  font-size: 25px;
  color: #999;

  &:before {
    content: '';
    flex: 1;
  }

  &-content {
    flex: 2;
    width: 100%;
    text-align: center;
  }
}
