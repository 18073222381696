@import 'src/styles/lib';

.CenteredLogo {
  height: 100%;
  width: 100%;
  padding: 60px;

  @media (max-width: 935px) {
    padding: 20px 15px;
  }

  @media (max-height: 650px) {
    padding: 20px 15px;
  }

  &-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }

  &-icon {
    fill: #ebebeb;
    width: 300px;
    height: 300px;
    margin-top: 30px;

    @media (max-height: 620px) {
      width: 200px;
      height: 200px;
    }

    @media (max-width: 992px) and (max-height: 480px) {
      height: 150px;
      transform: translateY(-10px);
    }
  }
}
