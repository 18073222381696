@import '../../styles/lib';

.Manage {
  position: relative;
  display: flex;
  justify-content: flex-end;
  height: auto;
  min-height: 100vh;
  padding: 81px 15px 15px 15px;

  &__heading-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 32px 0 16px 0;
  }

  &-main {
    position: relative;
    width: calc(100% - 315px);
    @media screen and (min-width: 1200px) {
      // Must correspond to the 300px in ManageSidebar.scss
      width: calc(100% - 415px);
    }
    padding-right: 30px;
    //height: auto;
    //min-height: 100%;
    @include tablet {
      width: 100%;
    }
  }

  &-round-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 19px;
    border: 4px solid #c3c3c3;
  }

  .Manage-main.Manage-round-container {
    padding: 20px 32px 20px 32px;
    @include mobile {
      padding: 15px;
    }
  }

  h1 {
    font-size: 22px;
    font-weight: 700;
    margin: 32px 0 16px 0;
    @include mobile {
      font-size: 18px;
    }
  }

  h2 {
    font-size: 18px;
    font-weight: 700;
    margin: 24px 0 8px 0;
    @include mobile {
      font-size: 16px;
    }
  }
}
