.ArgusBasicManagementButton {
  .IconButton-icon {
    background-color: transparent;
    color: #737373;
    fill: #737373;
    width: 24px;
    height: 24px;
    margin-right: 2px !important;
  }
}
