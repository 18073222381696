.UserRoleDetailCard {
  display: flex;
  flex-direction: row;

  &-left {
    flex: 1 1 auto;
  }

  &-right {
    flex: 1 1 auto;
    padding-left: 12px;
    text-align: right;
  }
}
