.UserSelector {
  &-list {
    border: 1px solid #999;
    border-radius: 8px;
    padding: 16px;
    margin: 10px 0;
    color: #3f3f3f;
    font-weight: 500;
    font-size: 16px;
    ul {
      padding: 0;
      margin: 0;
    }
    li {
      list-style: none;
    }
    &--error {
      border-color: #dc3545;
    }
  }
}
