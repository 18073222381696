@import '../../../styles/lib';

.Toolbar {
  width: 100%;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 11;

  &-mobile-header {
    display: none;
    align-items: center;
    padding: 19px 15px;

    cursor: pointer;
    background: #3f3f3f;
    color: #d4d4d4;

    span:hover {
      color: darken(#d4d4d4, 10%);
    }

    @include mobile {
      display: flex;
      margin-bottom: 4px;
    }
  }

  &-actions {
    padding: 10px 10px 2px 10px;
    @include mobile {
      padding-bottom: 5px;
    }
  }

  .ToolbarButton {
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 8px;

    @include mobile {
      margin-right: 5px;
      margin-bottom: 5px;
      padding: 0;
    }
  }
}
