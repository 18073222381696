.BillingAndPO {
  width: 100%;

  &__head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 20px;
  }

  .EditableTextCell {
    display: block;
    &-value {
      cursor: text;
      display: block;
      width: 100%;
      padding: 10px;
    }

    &-input {
      max-width: 300px;
      width: 100%;
      padding: 5px;
      outline: none;
      border: 1px solid transparent;
      border-radius: 3px;

      &:focus {
        box-shadow: none;
        border-color: #2a2a2a;
      }
    }
  }


  .EditableDateCell {
    display: block;
    &-value {
      cursor: pointer;
      display: block;
      width: 100%;
      padding: 10px;
    }
  }
}
