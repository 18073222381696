.BlockedUsersPickerField {
  display: flex;
  align-items: flex-end;

  @media (max-width: 440px) {
    flex-direction: column;
    align-items: flex-start;
    &-wrapper {
      width: 100%;
    }
    > button {
      margin-top: 10px;
    }
  }

  &-wrapper {
    flex: 1;
    margin-right: 20px;
  }

  &-label {
    color: #3f3f3f;
    display: block;
    font-size: 15px;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    line-height: 15px;
    margin-bottom: 8px;
  }

  &-selected-items {
    width: 100%;
    min-height: 39px;
    color: rgba(0, 0, 0, 0.87);
    border: 1px solid #707070;
    border-radius: 4px;
    padding: 6px 12px;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.87);
    }
  }
}
