.HelpModal {
  height: calc(100% - 56px);

  .ModalWrapper-header {
    padding: 0;
    z-index: 100;

    .ModalWrapper-title {
      font-size: 24px;
      font-weight: 600;
    }
  }

  .modal-content {
    height: 100%;
    padding: 26px 35px 15px 40px;
  }

  .modal-body {
    padding: 16px 0 0 0 !important;
    height: calc(100% - 20px);
  }

  .modal-footer {
    padding: 0 !important;
  }

  &-body {
    width: 100%;
    height: 100%;
    max-width: 600px;
    overflow-y: auto;
    margin: 0 auto;
    table {
      width: 100%;
    }
  }

  &-close {
    position: absolute;
    top: 0;
    right: 20px;
    padding: 5px;
    font-size: 50px;
    cursor: pointer;
    color: #404040;
    &:hover {
      color: #000000;
    }
  }
}
