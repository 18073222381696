.SystemMessages {
  padding: 0;
  margin: 15px 0 0 0;
  &-list-item {
    margin-top: 20px;
    list-style: none;
    color: #444444;
  }
  &-head {
    font-weight: bold;
  }
  &-date {
    margin-left: 10px;
  }
}
