.Card {
  background-color: white;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.2),
    0 0 1px 0 rgba(0, 0, 0, 0.12);

  padding: 24px;

  &--no-padding {
    padding: 0;
  }

  & > h2,
  & > h3 {
    font-size: 20px;
    font-weight: normal;
  }

  & > .HeadingBar > h2,
  & > .HeadingBar > h3 {
    font-size: 20px;
    font-weight: normal;
    margin: 0;
  }

  &--no-padding {
    padding: 0;

    & > h2,
    & > h3 {
      padding: 24px 12px 0 24px;
    }
  }

  &--accordion {
    padding: 0;
    box-shadow: none;
    background-color: transparent;
  }
}
