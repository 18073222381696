.PublicFooter {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 10px;
  text-align: center;
  p {
    margin: 0;
    color: #555555;
  }
  a {
    font-weight: bold;
  }
}
