/**
 * IMPORTANT
 *
 * This file is imported automatically before every .scss file in the project by
 * sass-resources-loader in the webpack config (both in dev and production). This
 * file must not output any CSS -- use it only for shared variables, configuration
 * and mixins.
 *
 *
**/

@import '~bootstrap/scss/functions';
@import 'bootstrap-variables';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

$header-height: 66px;
$mobile-header-height: 70px;
$mq-small: 767px;
$mq-medium: 992px;


/**
 * For hiding text while allowing screen readers to read it.
 * Copied from the sr-only class in Twitter Bootstrap.
 */
@mixin screenReaderOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/**
 * Space function corresponds to the Bootstrap utility functions, i.e.
 * class="ml-2" is equal to margin-left: space(2);
 */
@function space($idx) {
  @return nth(nth($spacers, $idx + 1), 2);
}


/**
Mixin for including styles for mobile
*/
@mixin mobile {
  @media (max-width: $mq-small) {
    @content;
  }
}

/**
Mixin for including styles for tablet
*/
@mixin tablet {
  @media (max-width: $mq-medium) {
    @content;
  }
}


/**
Mixin for including styles for desktop
*/
@mixin desktop {
  @media (min-width: ($mq-small + 1)) {
    @content;
  }
}
