.BehaviorPanel {
  width: 100%;
  &__chart {
    padding-top: 20px;
  }
}

.ModalOnMobile {
  position: fixed;
  pointer-events: all;
  touch-action: pan-y;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 11;
  background-color: white;
  padding: 10px;
  overflow: auto;

  &__btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__button {
    display: flex;
    align-items: center;
    font-size: 30px;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    opacity: 0.8;
    color: black;
    &:focus {
      outline: none;
    }
    &:hover {
      opacity: 1;
    }

    span {
      font-size: 15px;
      font-weight: bold;
      margin-right: 5px;
    }
  }
}
