.Vendor {
  background-color: #eeeae8;
  // padding: 78px 12px 12px 12px;
  min-height: 100vh;

  h2 {
    margin-bottom: 0;
  }

  &-body {
    flex: 1 1 auto;
    background-color: white;
    padding: 16px;
    margin-left: 12px;

    & > .HeadingBar:first-child {
      margin-top: 0;
      margin-bottom: 16px;
    }
  }

  // The header is already position:fixed by using bootstrap styles
  // &-header { }

  //&-nav {
  //}

  &-main {
    padding: 80px 24px 40px 24px;
    min-height: 100vh;
    margin-left: 260px;

    > h2 {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }

  > .Card + .Card {
    margin-top: 2rem;
  }
}
