.ManageHelpDropdown {
  display: inline-block;
  position: relative;
  &__button {
    position: relative;
    background-color: #ebf6dd;
    border: 0;
    font-size: 16px;
    padding: 5px 10px;
    padding-right: 32px;
    border-radius: 5px;
    font-weight: 500;
    color: #3c6305;

    &:hover {
      background-color: darken(#ebf6dd, 5);
    }
  }

  &__chevron {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 30px !important;
    height: 30px !important;

    &--up {
      transform: rotate(180deg);
    }
  }
  &__icon {
    width: 100%;
    justify-content: flex-start !important;
    border-radius: unset !important;

    .MuiButton-label {
      margin-right: 4px;
    }

    .IconButton-icon {
      background-color: #84b93c;
      width: 18px;
      height: 18px;
      margin-right: 7px;
      margin-left: 10px;
      min-width: unset;

      path {
        fill: white;
      }
    }
  }

  &__menu {
    position: absolute;
    background-color: white;
    z-index: 100;
    top: 45px;
    right: 0;
    border-radius: 7px;
    margin: 0 !important;
    padding: 10px 0;
    box-shadow: 0px 5px 18px -3px #b9b9b9;
    font-size: 15px;
    font-weight: 500;
    color: #3f3f3f;

    li {
      cursor: pointer;
      list-style: none;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}
