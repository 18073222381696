.Tabs {
  .MuiTabs-flexContainer {
    border-bottom: 1px solid #707070;
    padding-right: 15px;
  }

  .Tab {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #3f3f3f;
    padding: 0 16px;
    margin-bottom: -1px;

    border: 1px solid transparent;
    border-bottom: 1px solid #707070;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    &-icon {
      position: relative;
      display: inline-block;
      width: 24px;
      height: 24px;
      & > .Icon {
        width: 24px;
        height: 24px;
      }
    }

    &--right {
      margin-left: auto;
    }

    &.Mui-selected {
      color: #63951d;
      background: #fff;
      border-top-color: #707070;
      border-right-color: #707070;
      border-left-color: #707070;
      border-bottom-color: #fff;
    }

    &.MuiTab-textColorInherit {
      opacity: 1;
    }

    &:focus {
      outline: none;
    }
  }
  .MuiTab-root {
    min-width: auto;
  }
}
