@import 'src/styles/lib';

.HelpBlocks {
  display: flex;
  flex-flow: row wrap;

  & > .HelpBlock {
    margin: 16px;

    @include mobile {
      margin: 7px;
    }
  }
}
