@import './src/styles/lib';

.AlertSettingDetailsCard {
  display: flex;
  flex-direction: row;

  @include mobile {
    flex-direction: column;
    &-right {
      margin-bottom: 30px;
    }
  }

  &-left {
    flex: 1 1 auto;
    padding-right: 30px;
  }

  &-right {
    flex: 1 1 auto;
  }
}
