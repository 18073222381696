@import './src/styles/lib';
.Filters {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-right: 15px;

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
  }

  .MuiInputBase-input {
    &::placeholder {
      color: #3f3f3f;
      font-size: 14px;
      font-style: normal;
      font-family: Roboto, sans-serif;
      font-weight: 500;
      opacity: 1;
    }
  }

  .MuiFormControl-root.Filters-date {
    margin-right: 20px;
  }

  .MuiTextField-root {
    margin: 0;
    &.Filters-logtype {
      margin-right: 20px;
    }
  }

  .MuiIconButton-label {
    color: #444;
  }

  &-date {
    max-width: 160px;
  }

  &-zone {
    max-width: 350px;
  }
}
