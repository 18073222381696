.ZonePickerField {
  display: flex;
  align-items: flex-end;
  cursor: pointer;

  .MuiFormControl-root.ZonePickerField-textfield {
    flex: 1;
    margin-right: 20px;
  }

  @media (max-width: 440px) {
    flex-direction: column;
    align-items: flex-start;
    &-textfield {
      width: 100%;
      margin: 0 !important;
    }
    .MuiInputBase-root {
      margin-bottom: 10px;
    }
  }

  .MuiInputBase-root,
  input[type='text'] {
    cursor: pointer;
  }
}
