.ResetImageModal {
  margin: 0 !important;
  max-width: 100% !important;
  height: 100% !important;

  .modal-content {
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    border: none !important;
    border-radius: 0 !important;
  }
  .modal-body {
    height: 100%;
    display: flex;
    justify-items: center;
    align-items: center;
  }
}
