.MarkingApp {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1032;

  background-color: #e8e8e8;
  display: flex;

  &-sidebar {
    overflow: auto;
  }

  &-main {
    flex: 1 1 0;
    position: relative;
    overflow: auto;
  }

  &-controls {
    flex: 0 0 auto;
    margin: 0 16px;
  }

  &-objects {
    flex: 1 0 auto;
  }
}
