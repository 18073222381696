.FiltersWrapper {
  z-index: 100;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 30px;

  &-item {
    width: 200px;
    margin: 10px 20px 10px 0 !important;
  }

  &-input {
    font-weight: 500;
    input {
      padding-top: 9px;
      padding-bottom: 9px;
    }
  }

  &__btn-reset {
    margin-top: 10px !important;
  }
            
}
