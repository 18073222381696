.Panel {
  font-size: 15px;
  font-weight: 400;

  max-width: 600px;

  padding: 13px 40px 27px 18px;
  //padding: 16px 32px 16px 16px;
  border-radius: 6px;

  &--success {
    background-color: #ebf6dd;
    color: #3c6305;
  }

  &--warning {
    background-color: #fff3cd;
    color: #856404;
    // border-color: #ffeeba;
  }

  &--danger {
    background-color: #f5dee6;
    color: #bd3f0d;
    border-color: #c95d41;
  }

  h2,
  h3,
  h4,
  h5 {
    display: inline;
    font-family: inherit;
    font-weight: 900;
    font-size: 15px;
    &::after {
      content: ': ';
    }
  }

  ul {
    margin: 0;
    padding: 0 0 0 16px;
  }

  p:last-child {
    margin-bottom: 0;
  }
}
