.IconLegendHelp {


    h1 {
        font-size: 24px;
        font-weight: 600;
        margin: 0;
    }

    h2 {
        font-size: 18px;
        font-weight: 600;
        margin: 40px 0 8px 0;
    }

    &-legend {
        margin-bottom: 6px;
        .Icon {
            margin-right: 1rem;
        }
    }

}