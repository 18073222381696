.AccountAssignSsoProvider {
  background: #eeeeee;
  padding: 0 20px 20px 20px;
  &__provider {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
  }
  &__select {
    width: 200px;
  }
  &__input {
    width: 100%;
  }
  &__empty {
    text-align: center;
  }
  &__remove {
    align-self: center;
  }
}
