.ToolbarButton {
  background: none;
  border: none;
  padding: 2px;
  margin: 0;
  border: 1px solid transparent;

  border-radius: 8px;
  border: 3px solid #e3e3e3;

  background-color: rgba(255, 255, 255, 1);

  transition: background-color 0.1s ease-in-out,
    background-color 0.1s ease-in-out;
  &:hover:not(:disabled) {
    border-color: #565656;
    // background-color: rgba(0, 0, 0, 0.1);
  }

  &--active {
    //     border-color: #565656;
    border: 3px solid #565656;
  }

  color: black;

  .Icon {
    width: 52px;
    height: 52px;
  }

  &:disabled {
    background-color: rgba(244, 244, 244, 1);
    color: mix(#000, rgba(244, 244, 244, 1), 40%);
  }

  &--accentColor {
    color: #ff8422;
    &:disabled {
      color: mix(#ff8422, rgba(244, 244, 244, 1), 40%);
    }
  }
}
