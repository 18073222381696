.LabeledValue {
  margin-bottom: 22px;

  &-label {
    font-size: 16px;
    margin: 0;
    font-weight: 500;
  }

  &-value {
    font-size: 20px;
    margin: 0 0 0 0 !important;
  }
}
