.SensorSelector {
  margin-top: 20px;
  &-selected {
    background-color: #cbcbcb;
  }

  &-list {
    border: 6px solid #cbcbcb !important;
    .MuiTableCell-root {
      border-bottom-width: 2px !important;
      border-color: #cbcbcb !important;
      padding: 0;
    }
    &-item {
      cursor: pointer;
      font-size: 20px;
      font-weight: bold;
      padding: 5px 10px;
    }
  }
}
