@import './src/styles/lib';

.LogsFilterWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: row;
  @include tablet {
    align-items: flex-start;
    flex-direction: column;
    & > button {
      margin-top: 10px;
    }
  }
}
