.EventSummary {
  flex: 1 1 auto;
  padding: 8px 16px;
  &-image {
    margin-top: 12px;
    max-width: 800px;
    img {
      width: 100%;
      height: auto;
    }
  }
  &-bold {
    font-weight: 700;
  }
  &-supervision-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e8e8e8;
    color: black;
    border-radius: 8px;
    font-weight: 700;
    border: 2px solid #c0c0c0;
    padding: 0 10px 0 5px;
    margin-top: 10px;
    &:hover {
      background-color: darken(#e8e8e8, 3);
    }
  }
}
