@import 'src/styles/lib';

.HelpBlock {
  background-color: #6ca220;
  border: 0;
  text-align: left;

  border-radius: 14px;
  fill: white;
  color: white;

  position: relative;
  width: 180px;
  height: 180px;
  min-height: 50px;

  font-size: 14px;
  font-weight: 500;

  padding: 48px 32px 48px 16px;

  &--pdf {
    background-color: white;
    border: 3px solid #c3c3c3;
    color: #212529;
    font-weight: 500;
    font-size: 13px;
    padding-right: 30px;

    &:hover {
      background-color: darken(white, 3) !important;
    }
    @include mobile {
      padding-right: 55px;
    }
  }

  @include mobile {
    width: 100%;
    height: auto;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    padding: 15px 55px 15px 15px;
    margin: 5px;
  }

  &:hover {
    background-color: #508120;
    cursor: pointer;
  }

  &-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 32px;
    height: 32px;
  }
}
