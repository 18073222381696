.ReactTable {
  margin: 15px 0;

  th[role="columnheader"] {
    cursor: pointer;
  }

  &-footer {
    td {
      padding: 10px  5px !important
    }
  }
}
