@import '../../styles/lib';

.Care {
  display: flex;
  height: 100vh;

  &--old-layout {
    padding-top: $header-height;
    @media (max-width: 767px) {
      padding-top: $mobile-header-height;
    }
  }

  &-new-left {
    flex-basis: 350px;
    @include mobile {
      flex-basis: 100%;
    }
  }

  &-main {
    flex: 1;
    position: relative;
    overflow: hidden;

    background-color: #fff;

    display: flex;
    flex-direction: column;

    @include mobile {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1031;

      transition: 0.3s;
      transform: translateX(100%);
    }

    &--show {
      transform: translateX(0);
    }

    &-wrapper {
      flex-grow: 1;
      overflow-y: auto;
    }

    .SimulateEventsToolbarIfSensorisAvailable {
      position: fixed;
      bottom: 10px;
      left: 300px;
      background-color: #fff;
      border-radius: 8px;
      padding: 8px 8px 8px 16px;

      @include mobile {
        width: calc(100% - 15px);
        position: absolute;
        overflow: scroll;
        padding: 10px;
        left: 0;
      }

      .MuiButtonGroup-root {
        width: 100%;
      }
    }

    .informal {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    &-no-sensor-selected {
      font-size: 30px;
      color: #999;
    }

    &-sensor-info {
      display: flex;
      align-items: center;
      height: 50px;
      padding-left: 10px;

      &-name {
        font-size: 17px;
        font-weight: 600;
        margin: 0;
      }
    }
    &-image-streamer--with-heading {
      height: calc(100% - 50px);
    }
  }

  &-sidebar {
    &--hide {
      .Sidebar-body {
        overflow: hidden;
      }
    }
  }

  &-splash-title {
    font-size: 24px;
    font-weight: 500;
    margin: 0;

    @include tablet {
      font-size: 20px;
    }

    @include mobile {
      margin-bottom: 15px;
    }
  }
}
