.RestartMobileAppWarning {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;

  &-message {
    text-align: center;
    color: #82b64a;
    font-size: 19px;
    font-weight: 500;
  }

  &-button {
    display: flex !important;
    margin: 0 auto !important;
  }

  &-logo {
    position: absolute;
    bottom: 40px;
    right: 50%;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
    width: 120px;
    .st0 {
      fill: #a3a3a3;
    }
  }
}
