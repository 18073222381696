.SensorStreaming {
  &-image {
    width: 100%;
    padding-bottom: 75%;
    height: 100px;
  }

  .ImageStreamer-img {
    width: 100%;
  }

  &-nostream {
    color: #666;
    font-size: 18px;
    padding: 20px 8px;
    text-align: center;
  }

  display: flex;
  flex-flow: row nowrap;

  &-nav {
    flex: 0 0 140px;
    margin-top: -8px;
    padding-right: 24px;
  }

  &-body {
    flex: 1 1 0;
    min-width: 250px;
  }
}
