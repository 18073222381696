.ChangedFieldsSummary {
  td,
  th {
    line-height: 16px;
    padding: 2px 8px 2px 2px;
    border: 1px solid #999 !important;
  }

  th {
    font-size: 12px;
    background-color: #eee;
  }

  td {
    font-size: 13px;
  }
}
