.ChangePasswordForm {
  margin-bottom: 20px;
  margin-top: 10px;

  &-errors {
    margin-top: 10px;
    ul {
      padding-left: 20px;
    }
  }
}
