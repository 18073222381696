@import '../../styles/lib';

$background-color: #3f3f3f;
$foreground-color: #cdcdcd;

.CareHeader {
  position: relative;
  width: 100%;
  background-color: $background-color;
  font-family: Roboto Condensed;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &--nav {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    background-color: $background-color;
    // color: $foreground-color;
    z-index: 30;
    font-size: 17px;
    font-weight: 500;

    .nav-item {
      cursor: pointer;
      padding: 10px 15px;
      border-bottom: 1px solid rgba(204, 204, 204, 0.5);

      &:first-child {
        border-top: 1px solid rgba(204, 204, 204, 0.5);
      }
    }

    .nav-link {
      color: $foreground-color;
      padding: 0;

      &:hover {
        color: #fff;
      }
    }
  }

  &--user-info {
    color: $foreground-color;
    font-weight: 100;
    font-size: 20px;
  }

  &--select-zone-btn {
    width: 17px;
    color: $foreground-color;
    .Icon {
      position: absolute;
      top: 7px;
      right: 6px;
      height: 35px;
      width: 35px;
    }
    &:hover {
      color: darken($foreground-color, 10);
    }
  }
  &--logo {
    text-align: center;
    padding: 15px 0 !important;
    .navbar-brand {
      text-align: center;
    }
    &-icon {
      height: 30px;
      width: auto;
      fill: rgba(204, 204, 204, 0.5);
      &:hover,
      &:focus {
        fill: rgba(255, 255, 255, 0.5);
      }
    }
  }

  &--lang-item {
    color: $foreground-color;

    svg {
      margin-right: 5px;
    }

    &:hover {
      color: #fff;
    }
    &:first-child {
      border-top: unset !important;
    }
    &:last-child {
      border-bottom: unset !important;
      padding-bottom: 0;
    }
    &--active {
      color: #fff;
      font-weight: 600;
    }
  }
}
