.MarkingSidebar {
  width: 350px;
  flex: 0 0 350px;

  // width: 400px;
  // min-width: 400px;

  // @media (max-width: 992px) {
  //   min-width: 300px;
  // }

  padding: 16px;
  display: flex;
  flex-direction: column;

  &-info {
    margin: 24px 0;
    &-text {
      margin: 5px 0 !important;
    }
  }

  h2.MarkingSidebar-zone {
    font-size: 18px;
    font-weight: 600;
  }

  h3.MarkingSidebar-heading {
    font-size: 16px;
    font-weight: 600;
    margin: 24px 0 8px 0;
  }

  .MarkingSidebar-help {
    margin-top: auto;
  }
}
