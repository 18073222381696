.InlineSpinner {
  display: inline-flex;
  align-items: center;
  &-spinner {
    border-color: #84b93c !important;
    border-right-color: transparent !important;
  }
  &-text {
    margin-left: 5px;
  }
}
