.Dashboard {
  padding: 15px;
  margin-top: calc(49px + 15px);

  height: calc(100vh - 49px - 15px);
  display: flex;
  flex-direction: column;

  background-color: #666;
  color: white;

  h4 {
    font-size: 29px;
    margin-bottom: 15px;
  }

  &--green {
    background-color: #83aa4d;
    border-radius: 35px;

    flex: 1;
    padding: 25px 30px;

    display: flex;
    flex-direction: column;
  }

  &-header {
  }

  &-heading {
  }

  &-date {
  }

  &-time {
  }

  &-overview {
    margin: 0 -8px;
  }

  &-footer {
    margin: 25px 30px;
    &-title {
      font-size: 20px;
      margin-bottom: 15px;
    }
    li {
    }
  }

  &-divideline {
    background-color: #fff;
    height: 5px;
    border-radius: 28px;
    opacity: 0.5;
    margin-top: 5px;
    margin-bottom: 30px;
  }
  &-connection-interrupted {
    display: flex;
    gap: 5px;

    &-icon {
      margin-top: 2px;
      width: 60px;
      height: 60px;
    }
    &-wrapper {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
