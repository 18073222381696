.UserFormRoles {
  background: #EEEEEE;
  padding: 20px;
  margin-top: 20px;

  &--disabled {
    opacity: .5;
    pointer-events: none;
  }

  &-add-role {
    display: flex;
    align-items: center;
  }

  &-select-role {
    width: 200px;
  }
}
