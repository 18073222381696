.Login {
  &-logo {
    width: 48px;
    height: auto;
  }

  &-idle-timeout {
    display: flex;

    .MuiSvgIcon-root {
      fill: #3498db;
    }
    p {
      margin-left: 0.1rem;
      color: #3498db;
      font-weight: 500;
    }
  }
  &-specific-error {
    color: #f44336;
    margin: 2px 0;
  }
}
