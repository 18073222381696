@import '../../styles/lib';
.Super {
  background-color: #eeeae8;
  // padding: 78px 12px 12px 12px;
  min-height: 100vh;
  transition: all 0.3s ease-in-out !important;

  @include mobile {
    .Super-main {
      margin-left: 0;
    }
    .Super-nav {
      z-index: 999;
      left: -250px;
    }
  }
  // &--mobile {
  //   .Super-main {
  //     margin-left: 0;
  //   }
  //   .Super-nav {
  //     z-index: 999;
  //     left: -250px;
  //   }
  // }

  h2 {
    margin-bottom: 0;
  }

  &-body {
    flex: 1 1 auto;
    background-color: white;
    padding: 16px;
    margin-left: 12px;

    & > .HeadingBar:first-child {
      margin-top: 0;
      margin-bottom: 16px;
    }
  }

  // The header is already position:fixed by using bootstrap styles
  // &-header { }

  &-nav {
    transition: all 0.3s ease-in-out !important;
    &-button {
      transition: all 0.3s ease-in-out !important;
      position: fixed !important;
      top: 1px;
      right: 0;
      z-index: 9999;
      transform: rotate(180deg);
      color: #fff !important;
      opacity: 0.5 !important;
    }
  }

  &-nav--open {
    .Super-nav-button {
      transform: rotate(0);
      color: rgba(255,255,255,0.5);
    }
    .Super-nav {
      left: 0;
    }
  }

  &-main {
    transition: all 0.3s ease-in-out !important;
    padding: 80px 24px 40px 24px;
    min-height: 100vh;
    margin-left: 260px;

    > h2 {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }

  > .Card + .Card {
    margin-top: 2rem;
  }
}
