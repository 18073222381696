.BillingAndPoForm {
  &__wrapper {
    display: flex;
    align-items: flex-start;
    margin-top: 15px;
    margin-bottom: 30px;
  }

  &__input {
    input {
      padding: 10px;
    }
    button {
      padding: 2px;
      margin: 0 !important;
    }
  }
  button {
    margin-top: 8px;
  }
}
