.AccountSettings {
  &-content {
    display: flex;
    margin-top: 20px;
    @media (max-width: 576px) {
      flex-direction: column;
    }
  }

  &-sidebar {
    flex: 0 0 200px;
    margin-right: 50px;
    border-right: 1px solid #8f8f8f;
    padding-top: 20px;
    padding-right: 20px;

    @media (max-width: 576px) {
      flex: 0;
      margin-right: 0;
      border-right: none;
      border-bottom: 1px solid #8f8f8f;
    }
  }

  &-page {
    flex: 1 0 0;
  }
}
