@import '../../styles/lib';

$foreground-color: #cdcdcd;

.Header {
  .navbar-toggler {
    border: none;
    outline: none;
    padding: 5px;
  }

  .navbar-brand {
    margin-right: 0;
  }

  & > .navbar {
    background-color: #3f3f3f;
    padding: 12px;

    .navbar-nav {
      @include mobile {
        margin-left: 8px;
      }
    }
  }

  .navbar-dark .navbar-nav .nav-link {
    color: $foreground-color;
    font-size: 16px;
    font-weight: 700;
  }

  .navbar-dark .navbar-nav .nav-link:hover,
  .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 1);
  }

  .nav-link-mobile-username {
    color: $foreground-color;
    @media (max-width: 768px) {
      font-weight: 100;
      font-size: 20px;
    }
  }

  &-logo {
    height: 30px;
    width: auto;
    fill: $foreground-color;
    &:hover,
    &:focus {
      fill: #fff;
    }
  }

  &--super {
    & > .navbar {
      background-color: #660000;
    }
  }

  &--care {
  }

  &--manage {
    .manage-treeview-toggler {
      background: none;
      border: none;
      padding: 0;
      margin-left: 4px;
      color: $foreground-color;
      &:hover {
        color: white;
      }

      @include mobile {
        position: absolute;
        left: 50px;
        top: 18px;
      }
    }

    .navbar-nav {
      @include tablet {
        margin-left: 25px;
      }
    }
  }
}
