.SensorHistoryTable {
    &-table {
      th,
      td {
        padding: 3px 8px 3px 4px;
        border: 1px solid #ccc;
        font-size: 13px;
        white-space: nowrap;

        &:first-child {
          padding-right: 16px;
        }
      }
    }
  }
