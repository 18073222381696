.DashboardEventCounter {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;

  li {
    display: flex;

    align-items: center;
    justify-content: space-between;

    padding: 30px;

    margin-right: 15px;

    border: 2px solid rgba(#ffffff, 0.5);
    border-radius: 35px;

    background-color: #717171;
    color: white;
    fill: white;

    font-size: 32px;
    font-weight: bold;
    width: 150px;

    padding: 0 20px 0 16px;
  }

  .Icon {
    margin-top: -5px;
    margin-left: -5px;
    width: 48px;
    height: 48px;
  }
}
