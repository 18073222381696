.AccountDetails {
  &-content {
    margin-bottom: 40px;

    h3 {
      font-size: 18px;
      margin: 0;
    }

    &-value {
      font-size: 20px;
      margin: 10px 0 0 0 !important;
    }
  }
}
