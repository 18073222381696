@import '../styles/_lib';

.TreeView {
  margin: 0;
  padding-left: 0;
  padding-right: 7px;
  list-style-type: none;

  ul {
    margin: 0;
    padding: 0 0 0 21px;
    list-style-type: none;
  }

  li {
    padding-top: 0;
    padding-bottom: 0;
  }
  &-settings-indicator {
    right: 30px;
  }

  &-branch {
    color: #000000;
    ins {
      width: 24px;
      -webkit-user-select: none;
      display: inline-block;
      height: 28px;
      margin: 0;
      padding: 2px 0 0 0;
      text-decoration: none;
    }

    background-position: -99px 0;
    background-repeat: repeat-y;
    background-image: url('../images/firebase-data-tree-sprites.png');
    &:last-child {
      background: transparent;
    }

    &-wrapper {
      position: relative;
      border-radius: 6px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-top-zone:hover &-account-link {
      display: block;
    }

    &--disabled {
      color: #999;
      .TreeView-branch-lock-icon {
        color: #999 !important;
      }
    }

    &--active {
      color: #566c2a !important;
    }

    &--active > &-wrapper {
      background-color: #d6ebb0 !important;
    }

    &-icon {
      width: 19px;
      height: 19px;
      margin-right: 4px;
      font-size: 13px;
      transform: translateY(-1px);
      fill: currentColor;

      &--zone {
        transform: translateY(3px);
      }
    }

    &-lock-icon.MuiSvgIcon-root {
      width: 17px;
      height: 17px;
      margin-bottom: 2px;
      color: #757575;
    }

    &-account-link {
      display: none;
      color: #222222;
      width: 25px;
      height: 25px;
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &-zone-name {
    font-size: 16px;
    font-weight: 700 !important;
  }

  &-icon {
    background-image: url('../images/firebase-data-tree-sprites.png');
    background-position: -66px 0; // NO ICON
    background-repeat: no-repeat;

    &--plus {
      background-position: -33px 0; // PLUS
    }

    &--minus {
      background-position: 0 0; // MINUS
    }
  }

  // Hide the tree structure icon for the root branch
  & > .TreeView-branch > div > .TreeView-icon {
    visibility: hidden;
  }
}
