@keyframes Icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.Icon {
  width: 32px;
  height: 32px;
  fill: currentColor;

  &--spin {
    -webkit-animation: Icon-spin 2s infinite linear;
    animation: Icon-spin 2s infinite linear;
  }
}
