.CareCard {
  display: block;
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  padding: 8px 42px 8px 12px; // Leave room for the icon
  border: 3px solid #999;
  background-color: #eee;
  margin: 0 0 7px 0;
  transition: background-color 0.15s ease-in-out;

  &--selected {
    background-color: #fff;
    // Important to override custom border-colors on SensorCard, CareCard
    border: 3px solid black !important;
  }

  &-area {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    padding-bottom: 0px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  &-name {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 19px;
    font-weight: 700;
    line-height: 23px;
  }

  &-icon {
    margin-left: auto;
    width: 32px;
    height: 32px;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 22px;
    line-height: 22px;
    text-align: center;
  }
}
