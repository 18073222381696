.SensorSettings {
  .btn-action {
    padding: 2px 5px;
    font-size: 12px;
    min-width: 40px;
  }

  .btn-action-edit {
    visibility: hidden;
  }
  &:hover {
    .btn-action-edit {
      visibility: visible;
    }
  }
}
