.UserDetails {
  &-wrapper {
    margin-top: 40px;
    margin-bottom: 100px;
  }

  &-content {
    margin-bottom: 40px;

    h3 {
      font-size: 18px;
      margin: 0;
    }

    &-value {
      font-size: 20px;
      margin: 10px 0 0 0 !important;
    }
  }

  &-actions {
    button {
      margin-right: 26px;
    }
  }

  &-roles {
    @media (max-width: 575px) {
      margin-top: 30px;
    }
  }
}
