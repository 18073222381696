$alarm: #ec3926;
$alarm-alt: darken($alarm, 15%);

$alert: #fcea50;
$alert-alt: darken($alert, 20%);

$attention-interval: 0.8s;

.CareIndicator {
  position: relative;
  background: #e8e8e8;
  min-width: 70px;
  border: 3px solid #c0c0c0;
  border-radius: 8px;
  padding: 0;
  display: flex;
  align-items: center;
  color: black;
  height: 65px;

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 100%;
  }

  &.selected {
    border: 3px solid #000;
  }

  &:focus {
    outline: none;
    border: 3px solid #000;
  }

  /**
   * Text
   */
  &-label {
    font-size: 17px;
    line-height: 1;
    font-weight: bold;
    margin-bottom: 4px;
  }

  &-value {
    font-size: 30px;
    line-height: 1;
    font-weight: bold;
  }

  /**
   * Attention
   */
  &--alarms {
    background-color: $alarm;
    color: white;
    &.attention {
      animation: blinkAlarm $attention-interval ease-in-out infinite;
    }
  }

  &--alerts {
    background-color: $alert;
    &.attention {
      animation: blinkAlert $attention-interval ease-in-out infinite;
    }
  }

  &--info {
    max-width: 65px;
    min-width: unset;

    .Icon {
      width: 52px;
      height: 52px;
    }
  }

  &-story {
    min-width: 78px;
    &--info {
      width: 65px;
      height: 65px;
    }
  }
}

@keyframes blinkAlarm {
  0%,
  100% {
    background-color: $alarm;
  }
  50% {
    background-color: $alarm-alt;
  }
}

@keyframes blinkAlert {
  0%,
  100% {
    background-color: $alert;
  }
  50% {
    background-color: $alert-alt;
  }
}
