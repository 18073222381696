.MarkingCanvas {
  // position: relative;
  // margin-top: 24px;

  &-marking {
    // This is the actual canvas. We control its width/height using JS when redrawing,
    // so here we just make sure its glued to the top left corner in the container.
    position: absolute;
    top: 0;
    left: 0;
    cursor: crosshair;
  }

  &-loading {
    background-color: #444;
    color: white;
    text-align: center;
    font-size: 24px;
    padding-top: 100px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
