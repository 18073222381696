@import 'src/styles/lib';

.ItemPicker {
  .ModalWrapper-header {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .ModalWrapper-close {
    z-index: 101;
  }

  @media (max-width: 991px) {
    &.modal-dialog {
      max-width: 100%;
      margin: 1.75rem 15px;
    }
  }

  .modal-content {
    min-height: 600px;
    padding: 45px 40px 40px 40px;

    @media (max-width: 576px) {
      padding: 30px;
    }
  }

  .modal-body {
    display: flex;
    padding: 0;
    margin-top: -30px;
    z-index: 100;
  }

  .modal-footer {
    padding-top: 0;
    margin-top: 40px;
  }

  &-body {
    padding: space(4);
  }

  &-items {
    list-style-type: none;
    padding: 0;
    // Add negative margin here and positive margin on the
    // items to leave room for the focus outline
    margin: space(1) * -1;
  }

  &-label {
    font-size: 16px;
    margin-bottom: -10px;
  }

  &-container {
    overflow-y: auto;
    max-height: 50vh;
  }

  &-close-modal {
    font-size: 30px;
    opacity: 0.8;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
}

.ItemPicker-item {
  margin: space(1);
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ItemPickerBody {
  display: flex;
  width: 100%;

  h2 {
    font-weight: 700;
    font-size: 22px;
    color: #3f3f3f;
    margin-bottom: 30px;

    @media (min-width: 577px) {
      margin-bottom: 45px;
    }
  }

  &-left {
    flex: 1;
    display: flex;
    flex-direction: column;

    @media (min-width: 577px) {
      margin-right: 10px;
    }
  }

  &-selected-items {
    flex: 1;
    height: 100%;
    min-height: 364px;
    max-height: 364px;
    overflow: auto;

    border: 1px solid #656565;
    border-radius: 5px;
    padding: 15px;
  }

  &-selected-item {
    display: flex;
    align-items: center;
    &-close {
      cursor: pointer;
      font-size: 28px;
      margin-right: 10px;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
      @include mobile {
        font-size: 20px;
        margin-right: 5px;
      }
    }

    &-label {
      font-weight: 500;
      font-size: 20px;
      color: #535353;
      @include mobile {
        font-size: 16px;
      }
    }
  }

  &-right {
    flex: 1;
    display: flex;
    flex-direction: column;

    @media (min-width: 577px) {
      margin-left: 10px;
    }

    .MuiInputBase-root {
      margin-top: 0;
    }
  }

  &-search-results {
    height: 100%;
    min-height: 305px;
    max-height: 305px;
    overflow: auto;
    border: 1px solid #656565;
    border-radius: 4px;
    margin-top: 20px;
    &-item {
      padding: 5px 10px;
      border-bottom: 1px solid #d0d0d0;
      cursor: pointer;
      font-size: 20px;
      font-weight: 500;
      color: #535353;
      &:hover {
        background-color: #eee;
      }

      @include mobile {
        font-size: 16px;
      }
    }
    &-placeholder {
      padding: 5px 10px;
      text-align: center;
    }
  }

  &-user-type-checkbox {
    margin-top: 7px;
    margin-bottom: -45px !important;
  }

  @media (max-width: 576px) {
    flex-direction: column-reverse;
    &-left {
      padding-right: 0;
      margin-top: 15px;
    }
    &-right {
      padding-left: 0;
    }

    &-user-type-checkbox {
      margin-top: 10px !important;
      margin-bottom: 0 !important;
    }
  }
}
