@import '../../../styles/lib';

.AlertIconsForSensor {
  display: inline-flex;

  &-event {
    display: flex;
    border-radius: 5px;
    background-color: #e2e2e2;
    justify-content: center;
    align-content: center;
    position: relative;
    margin-right: 0.5rem;
    padding: 2px;
  }

  &-event-link:hover {
    background-color: #d2d2d2;
  }

  &-icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    fill: #aaa;
    &.enabled {
      fill: #222;
    }
  }

  &--large {
    .AlertIconsForSensor-icon {
      width: 48px;
      height: 48px;
    }
  }

  &-level {
    // // Old style, small bar below icon
    // position: absolute;
    // bottom: -6px;
    // height: 4px;
    // width: 10px;
    // left: 50%;
    // transform: translateX(-50%);
    // // width: 9px;
    // border-radius: 9px;

    // New style, circle to the top right
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    top: -5px;
    right: -5px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    outline: 1px solid white;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;

    &--info {
      background-color: blue;
    }
    &--alert {
      background-color: #fff266;
    }
    &--alarm {
      background-color: #ff6666;
    }
  }
}
