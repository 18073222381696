.SimulateModal {
  &-note {
    font-size: 13px;
    margin-top: 8px;
    color: #fd2d0b;
  }

  .ModalWrapper-footer {
    padding-top: 20px;
  }
}
