@import '../../styles/_lib.scss';

.PublicLanguageSelector {
  display: inline-block;
  position: relative;
  color: #3f3f3f;

  &__toggler {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 0;
    font-size: 16px;
    border-radius: 5px;
    line-height: 20px;

    &:hover {
      color: darken(#3f3f3f, 5);
    }
  }

  &__chevron {
    width: 30px !important;
    height: 30px !important;
    &--up {
      transform: rotate(180deg);
    }
  }

  &__globe-icon {
    width: 20px !important;
    height: 20px !important;
    margin-right: 5px;
  }

  &__menu {
    position: absolute;
    background-color: white;
    z-index: 100;
    top: 45px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 7px;
    margin: 0 !important;
    padding: 20px 0;
    box-shadow: 0px 5px 18px -3px #b9b9b9;
    border: 1px solid #d0d0d0;
    min-width: 150px;

    @include mobile {
      left: unset;
      transform: translateX(0);
      right: 0;
    }

    li {
      cursor: pointer;
      list-style: none;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-grow: 1;
      padding: 5px 20px;
      &:hover {
        background-color: #f6f6f6;
      }
    }
  }
  &__checkmark-icon {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
}
