@import './src/styles/lib';

.SensorDisplayName {
  &-option {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 7px 0;

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 15px;
    }

    &-card {
      margin-right: 15px;
      width: 210px;
    }

    &-label {
      flex: 1 1 0;
    }
  }
}
