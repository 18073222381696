.TableLoadingPlaceholder {
  height: 70px;
  &__cell {
    text-align: center !important;
  }
  &__wrapper {
    display: inline-flex !important;
    flex: 0 0 auto !important;
    align-items: center;
  }

  &__dot-flashing {
    display: inline-block;
    position: relative;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #00000077;
    color: #00000077;
    animation: dotFlashing 0.7s infinite linear alternate;
    animation-delay: 0.2s;
    margin-left: 15px;

    &:before,
    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
    }

    &::before {
      left: -10px;
      width: 5px;
      height: 5px;
      border-radius: 5px;
      background-color: #00000077;
      color: #00000077;
      animation: dotFlashing 0.7s infinite alternate;
      animation-delay: 0s;
    }

    &::after {
      left: 10px;
      width: 5px;
      height: 5px;
      border-radius: 5px;
      background-color: #00000077;
      color: #00000077;
      animation: dotFlashing 0.7s infinite alternate;
      animation-delay: 0.4s;
    }

    @keyframes dotFlashing {
      0% {
        background-color: #00000077;
      }
      50%,
      100% {
        background-color: #0000003b;
      }
    }
  }
}
