.DashboardHeader {
  display: flex;

  font-size: 30px;
  font-weight: 600;

  &-date {
    text-transform: capitalize;
  }

  &-title {
    text-align: center;
    flex: 2;
    font-size: 3rem;
    font-weight: 600;
    margin-top: -10px;
  }

  & > :first-child {
    flex: 1;
  }

  & > :last-child {
    flex: 1;
    text-align: right;
  }
}
