.ReceiptPage {
  // Ensure that we don't stretch the image all over the screen
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 95px;
  position: relative;
  min-height: 100vh;

  &-logo {
    position: absolute;
    bottom: 20px;
    right: 50%;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
    width: 120px;
    .st0 {
      fill: #a3a3a3;
    }
  }

  &-stream-button {
    font-weight: 500;
    border: 3px solid #918f90;
    border-radius: 10px;
    padding: 7px;
    font-size: 20px;
    background-color: #e4e4e4;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    color: black;
  }

  &-image {
    & > img {
      width: 100%;
      height: auto;
      display: block;
      max-height: 80vh; // Ensure we don't fill the screen too much
      max-width: 500px;
      margin: 8px auto;
    }
  }

  &-handle-button {
    border-radius: 20px;
    border: 3px solid #918f90;
    background-color: #e4e4e4;
    color: #616161;
    text-align: center;
    padding: 8px 20px;
    margin: 32px 0;
    font-size: 24px;
    font-weight: 500;
    &-label {
      color: #000;
    }
  }

  &-handle-icon {
    width: 75px;
    height: 60px;
  }

  &--handled {
    .ReceiptPage-handle-button-label {
      display: none;
    }
    .ReceiptPage-handle-button {
      color: #84b93c;
      border: 0;
      background-color: transparent;
      margin: 0;
    }

    .ReceiptPage-handle-icon {
      width: 100px;
      height: 100px;
    }
  }

  // Remove default button styling for IOS
  button {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}
