.CareHelpPage {
  height: 100%;
  text-align: left;
  color: #333;

  padding: 60px;

  @media (max-width: 935px) {
    padding: 20px 15px;
  }

  @media (max-height: 650px) {
    padding: 20px 15px;
  }

  &-download {
    margin-top: 30px !important;
    margin-left: 15px !important;

    svg {
      background: transparent !important;
    }

    path {
      fill: #000;
    }
  }
}
