@import 'src/styles/lib';

.SelectRole {
  .roles-selection-list {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      border-radius: 8px;
      border: 1px solid #555555;
      color: #555555;
      font-size: 20px;
      font-family: 'Roboto';
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 10px;
      padding: 10px;
      cursor: pointer;

      &.selected {
        background: #e2facf;
        background-image: url('../../images/tick.png');
        background-repeat: no-repeat;
        background-size: 34px;
        background-position: right 20px center;
        padding-right: 55px;
      }

      &:hover:not(.selected) {
        background-color: #eee;
      }

      @include mobile {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  .role-selection-actions {
    margin-top: 90px;
    .btn-continue {
      padding: 11px 20px;
      @media (min-width: 992px) {
        margin-right: 40px;
      }
    }
    .btn-cancel {
      padding: 11px 30px;
    }
  }
}
