.SelectMarkingObject {
  border-top: 1px solid #ccc;
  list-style-type: none;
  padding: 0;

  li {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #ccc;
  }

  label {
    display: flex;
    align-items: center;
    padding: 2px 0;
    margin: 0;
  }
}
