@import './src/styles/lib';

.SensorEventSettings {
  position: relative;
  display: flex;
  border: 1px solid #707070;
  border-radius: 10px;
  margin-top: 30px;
  overflow: hidden;

  h2 {
    font-size: 22px !important;
  }

  &-sidebar {
    position: relative;
    border-right: 1px solid #707070;
    padding: 20px 15px 20px 35px;
    min-width: 350px;

    &--desktop {
      @media (max-width: 991px) {
        display: none;
      }
    }

    &--mobile {
      border-right: none;
      padding: 0;
      min-width: 100%;
    }
  }

  &-content {
    width: 100%;
    padding: 20px 50px;

    @media (max-width: 991px) {
      padding: 20px;
    }

    h2 {
      margin-bottom: 30px !important;
    }
  }

  .MuiListItem-button {
    &:hover,
    &.Mui-selected {
      background-color: transparent !important;
      color: #84b93c;

      .MuiListItemIcon-root {
        color: #8bc141 !important;
      }
    }
  }

  .MuiListItemIcon-root {
    color: #212529;
  }

  .MuiTypography-body1 {
    font-weight: 500;
  }
}
