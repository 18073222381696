.SearchCareReceiver {
  position: relative;
  display: flex;
  align-items: flex-end;

  .MuiFormGroup-root {
    position: relative;
    .MuiInputBase-formControl {
      padding-right: 20px;
    }
  }

  .btn-clear {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    color: #333;
    &:hover {
      color: #000;
    }
  }
}
