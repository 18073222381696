.AvailableOtherSensorsListSelect {
  ul {
    border: 1px solid #000;
    border-radius: 4px !important;
    height: 200px;
    overflow: auto;
  }

  li {
    cursor: pointer;
    &.selected {
      font-weight: 900;
    }
  }
}
