.ButtonGroup {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  .Button,
  .IconButton {
    flex: 0 0 auto;
    margin: 0 18px 18px 0;

    // &:first-child {
    //   margin-top: 0 !important;
    //   margin-left: 0 !important;
    // }
    // &:last-child {
    //   margin-right: 0 !important;
    //   margin-bottom: 0 !important;
    // }
  }

  &--horizontal {
    flex-wrap: wrap;
    align-items: flex-start;
    // .Button {
    //   margin: 0 9px 9px 0;
    // }
  }

  &--vertical {
    display: inline-flex;
    flex-direction: column;

    .Button,
    .IconButton {
      margin: 8px 0;
    }
  }
}
