@import '../../../../styles/lib';

.SearchResult {
  cursor: pointer;

  &-icon {
    width: 25px;
    height: 25px;
  }

  &-heading {
    font-size: 18px;
    margin-bottom: 4px;
  }

  code {
    display: inline-block;
    color: #222;
    background-color: rgba(127, 0, 0, 0.1);
    padding-left: 3px;
    padding-right: 3px;
    letter-spacing: 0.5px;
  }

  &-status {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin: 0 7px;

    &--online {
      background-color: $success;
    }
    &--offline {
      background-color: $danger;
    }
  }
  &--account {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
