.VkpReceiptModal {
  .modal-body {
    padding: 0;
  }

  .modal-footer {
    padding-top: 20px;
  }

  &-comment-input {
    .MuiOutlinedInput-root {
      margin-top: 10px;
      border-radius: 10px;
    }
    textarea {
      overflow: auto !important;
      height: 150px !important;
      padding-right: 5px;
    }
  }
}
